<template>
  <VCard>
    <template #title>
      {{ getSetting("name") }}
    </template>

    <template #content>
      <div>
        {{ getSetting("details") }}
      </div>

      <VSelect
        :model-value="setting.value"
        :options="options"
        :readonly="['NOTIFY_EMAIL', 'NOTIFY_SMS'].includes(setting.code)"
        @update:model-value="onUpdate"
      />
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Components
import VCard from "@/components/VCard";
import VSelect from "@/components/inputs/VSelect";

export default {
  components: {
    VCard,
    VSelect
  },
  props: {
    setting: {
      type: Object,
      required: true
    }
  },
  emits: ["update"],
  setup(props, { emit }) {
    // MISC
    const { t } = useI18n();

    // COMPUTED
    const options = computed(() => {
      const options = props?.setting?.options || [];

      return options.map(option => ({
        ...option,
        text: Number.isInteger(option.text)
          ? option.text
          : t(`backend.${option.text}`),
        value: option.value
      }));
    });

    // METHODS
    const onUpdate = value => {
      const code = props.setting.code.toLowerCase();
      emit("update", { [code]: value });
    };

    const getSetting = attrs => {
      const value = props.setting[attrs] ?? "";
      const key = value.split(".").join("");

      return value ? t(`backend.${key}`) : "";
    };

    return {
      onUpdate,
      getSetting,
      options
    };
  }
};
</script>
