<template>
  <div>
    <VTitle :title="$t('app.ui_settings')" class="section-title" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert
      v-else-if="settings.length === 0"
      :text="$t('app.no_results_found')"
    />

    <div v-else>
      <div class="grid responsive-cols gap-4">
        <UISetting
          v-for="(setting, index) in settings"
          :key="index"
          :setting="setting"
          @update="onUpdate"
        />
      </div>

      <FullPagination class="mt-8" :pagination="pagination" />
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
// Composables
import usePaginate from "@/composables/usePaginate";
import useRequest from "@/composables/useRequest";
import useReadMultiple from "@/composables/useReadMultiple";
// Components
import UISetting from "./UISetting";
import VAlert from "@/components/VAlert";
import VTitle from "@/components/VTitle";
import FullPagination from "@/components/tables/FullPagination.vue";

export default {
  components: {
    UISetting,
    VAlert,
    VTitle,
    FullPagination
  },
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // MISC
    const store = useStore();
    const { t } = useI18n();

    // Computed
    const documentTitle = computed(() => {
      const title = `${t("app.ui_settings", 2)} - ${t("app.profile")}`;

      const { firstname, lastname } = props.user ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });
    const settings = computed(() => {
      const userSettings = props?.user?.settings || [];

      return data.value.map(setting => {
        const userSetting = userSettings.find(s => s.code === setting.code);
        const userSettingValue = userSetting?.pivot?.value || null;
        const parsedValues = JSON.parse(setting.values);

        return {
          ...setting,
          value: userSettingValue || parsedValues.default,
          options: formatOptions(parsedValues.options)
        };
      });
    });

    // LIFECYCLE HOOKS
    onMounted(async () => {
      await getData();
    });

    // Composables
    const { data, isLoading, read, pagination } = useReadMultiple();
    const { request, isSuccess } = useRequest();

    // METHODS
    const getData = async () => {
      await read({ endpoint: "ui-settings" });
    };

    const formatOptions = options => {
      return options.map(x => ({
        text: x,
        value: x
      }));
    };

    const onUpdate = async data => {
      const [code, value] = Object.entries(data)[0];
      // eslint-disable-next-line
      const setting = settings.value.find(setting => setting.code === code.toUpperCase());

      if (value === setting?.value) {
        return;
      }

      const response = await request({
        endpoint: "my.ui-settings",
        data
      });

      if (isSuccess(response)) {
        store.dispatch("user/updateUser", response?.payload?.data);
      }
    };

    // CUSTOM COMPOSABLES
    const { onUpdateCurrentPage } = usePaginate(pagination, getData);

    return {
      settings,
      isLoading,
      pagination,
      onUpdateCurrentPage,
      onUpdate,
      documentTitle
    };
  }
};
</script>
